import React, { ReactNode } from 'react'
import { ENGLISH_TITLE } from '../../data/English'
import { layoutList } from '../../styles/layout'
import { MvChildTop } from '../Mv/MvChildTop'
import { SideNavIR } from '../SideNav/SideNavIR'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
}

/**
 * 株主・投資家の皆さまへトップのレイアウト
 * @param {ReactNode} children
 */
/** */
export const LayoutSecondaryIR = ({ children }: Props) => {
  return (
    <LayoutRoot>
      <MvChildTop
        heading={'株主・投資家の皆さまへ'}
        headingEnglish={ENGLISH_TITLE.IR}
        BreadcrumbData={[{ title: '株主・投資家の皆さまへ', url: '' }]}
        imgPc={'/ir/img/top/image01.png'}
        imgSp={'/ir/img/top/image02.png'}
      />
      <div
        css={layoutList.layoutInner}
        className="flex justify-between flex-col pc:flex-row pc:items-start"
      >
        <div className="pc:w-[932px] pb-14 relative">{children}</div>
        <div className="pc:w-[212px] w-full">
          <SideNavIR />
        </div>
      </div>
    </LayoutRoot>
  )
}
