import { css } from '@emotion/react'
import { LinkBase } from '../../atoms/Link/LinkBase'
import { LinkWithIcon } from '../../atoms/Link/LinkWithIcon'
import { SvgArrowIconSizeM } from '../../atoms/Svg/SvgArrowIconSizeM'
import { hoverArrowIcon } from '../../styles/animation'

interface Props {
  imagePath: string
  head: { text: string; url: string }
  body: Array<{ text: string; url: string }>
}

/**
 * IRのTopページに使用されるカード、よくあるご質問の上のブロックで使用
 * @param {string} imagePath
 * @param {string} head
 */
/** */
export const CardIRTop = ({ imagePath, head, body }: Props) => {
  return (
    <li>
      <div className="relative pt-[44.8%]">
        <img
          src={imagePath}
          alt=""
          css={css`
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          `}
        />
      </div>
      <div className="bg-white px-6 pt-2 pc:px-8 pb-8">
        <div
          css={hoverArrowIcon}
          className="list-none border-b border-border10"
        >
          <LinkBase className="relative block py-6" href={head.url}>
            <div className={`font-bold text-lg`}>{head.text}</div>
            <div className="absolute right-0 top-[calc(50%-15px)]">
              <SvgArrowIconSizeM />
            </div>
          </LinkBase>
        </div>
        <div className="mt-6 pc:min-h-[108px]">
          <ul className="grid grid-cols-1 pc:grid-cols-2 gap-y-4 pc:gap-x-10">
            {body.map((item, i) => (
              <li key={i}>
                <LinkWithIcon
                  href={item.url}
                  iconType={item.text === '株価情報' ? 'external' : `arrow`}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
                </LinkWithIcon>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  )
}
